import { autorun, keys, observable } from 'mobx';
import moment from 'moment';
import { createContext } from 'react';
import { Subject, Subscription } from 'rxjs';
import { debounceTime, filter } from 'rxjs/operators';
import { PopupEvent } from '../components/common/popup/Popup';
import {
  COMMON_DEBOUNCE_DURATION,
  DATE_FORMAT_SHORTER,
  DEBOUNCE_AFTER_ACTION_DURATION,
} from '../config';
import {
  downloadFile,
  getFiles,
  getLettersAll,
  getLettersCases,
  getSelectedFiles,
  getSelectedLetters,
  printLetter,
  uploadFile,
} from '../server-api/api';
import { InfoFile, Letter, LetterCase } from '../server-api/model';
import { checkFileUploadResponse } from '../utils/checkUploadedFiles';
import { b64toBlob, blobToFile, openFile, openHtmlPage } from '../utils/fileUtils';
import { normalizeFilename } from '../utils/normalizeFilename';
import { appState } from './appState';
import {
  actionSuccessEvent,
  closedToggleEvent,
  modalCloseEvent,
  newDebtorEvent,
  toastSubject,
} from './rxjs';
const filterChangeSubj = new Subject<readonly string[]>();
const fileSelectSubj = new Subject<InfoFile>();

export class FilesState {
  @observable
  public loadingCases = false;
  @observable
  public loadingFiles = false;
  @observable
  public loadingLetters = false;
  @observable
  public loadedDebtorId: string | null = null;
  @observable
  public cases: LetterCase[] = [];
  @observable
  public selectedCases: Map<LetterCase, true> = new Map();
  @observable
  public letters: Letter[] = [];
  @observable
  public files: InfoFile[] = [];
  @observable
  public selectedLetter: Letter | null = null;
  @observable
  public selectedFile: InfoFile | null = null;
  @observable
  public initialized = false;
  @observable
  public droppedFile?: File | null;
  @observable
  public downloadingFile = false;
  @observable
  public noPreview = false;
  @observable
  public downloadingLetter = false;
  @observable
  public fileToShowURL: string | null = null;
  @observable
  public uploading = false;
  @observable
  public fileCategory?: string;
  @observable
  public fileComment = '';

  private autorunner1: any = null;
  private autorunner2: any = null;
  private subscriptions: Subscription | null = null;
  constructor() {
    modalCloseEvent
      .pipe(filter((id) => id === 'FilesUploadFileModal'))
      .pipe(debounceTime(400))
      .subscribe(() => {
        this.resetFileUploader();
      });
    closedToggleEvent
      .pipe(debounceTime(COMMON_DEBOUNCE_DURATION))
      .subscribe((isClosed) => {
        if (this.loadedDebtorId) {
          this.getFiles(this.loadedDebtorId);
          this.getLettersAll(this.loadedDebtorId);
          this.getLettersCases(this.loadedDebtorId);
        }
      });
    actionSuccessEvent
      .pipe(debounceTime(DEBOUNCE_AFTER_ACTION_DURATION))
      .subscribe(() => {
        if (this.loadedDebtorId) {
          this.getFiles(this.loadedDebtorId);
          this.getLettersAll(this.loadedDebtorId);
          this.getLettersCases(this.loadedDebtorId);
        }
      });
    newDebtorEvent.subscribe((debtorId) => {
      this.reset();
      if (debtorId) {
        this.init(debtorId);
      }
    });
  }

  public init(debtorId: string) {
    this.loadedDebtorId = debtorId;

    this.autorunner1 = autorun(() => {
      filterChangeSubj.next(keys(this.selectedCases));
    });
    this.autorunner2 = autorun(() => {
      if (this.selectedFile) {
        fileSelectSubj.next(this.selectedFile);
      }
    });
    this.initialized = true;
    this.subscriptions = filterChangeSubj
      .asObservable()
      .pipe(debounceTime(COMMON_DEBOUNCE_DURATION))
      .subscribe((keys) => {
        if (keys.length > 0) {
          this.getLettersForCases();
          this.getSelectedFiles(debtorId);
        } else {
          this.getLettersAll(debtorId);
          this.getFiles(debtorId);
        }
      });
    this.subscriptions.add(
      fileSelectSubj
        .asObservable()
        .pipe(debounceTime(COMMON_DEBOUNCE_DURATION))
        .subscribe(() => {
          if (this.selectedFile) {
            this.noPreview = false;

            if (
              ['jpg', 'jpeg', 'png', 'bmp', 'tiff', 'webp'].indexOf(
                this.selectedFile.fileDescriptor.name.split('.')[
                  this.selectedFile.fileDescriptor.name.split('.').length - 1
                ]
              ) === -1
            ) {
              this.fileToShowURL = null;
              this.noPreview = true;
              return;
            }
            this.downloadingFile = true;
            downloadFile(
              this.selectedFile.fileDescriptor.id,
              window.localStorage.getItem('sessionId')!
            )
              .then((blob) => {
                this.downloadingFile = false;
                if (blob.type.indexOf('image') === -1) {
                  this.fileToShowURL = null;
                  this.noPreview = true;
                  return;
                }
                this.fileToShowURL = URL.createObjectURL(blob);
              })
              .catch((err) => {
                this.fileToShowURL = null;
                this.noPreview = true;
                this.downloadingFile = false;
                toastSubject.next(err.message);
              });
          }
        })
    );
    this.getLettersCases(debtorId);
    this.getFiles(debtorId);
    this.getLettersAll(debtorId);
  }

  public downloadFile = (fileToDownload: InfoFile) => {
    downloadFile(
      fileToDownload.fileDescriptor.id,
      window.localStorage.getItem('sessionId')!
    )
      .then((file) => {
        if (file.size === 0) {
          toastSubject.next('This file is unavailable');
          return;
        }

        const link = document.createElement('a');
        link.href = URL.createObjectURL(file);
        link.download = fileToDownload.fileDescriptor.name;
        link.target = '_blank';
        link.click();
      })
      .catch((err) => {
        toastSubject.next(err.message);
      });
  };

  public uploadFiles = () => {
    return new Promise<void>((resolve) => {
      if (!this.droppedFile) {
        toastSubject.next('Please choose a file to upload');
        return;
      }
      const formData = new FormData();
      const filesInfo: Array<{}> = [];
      this.uploading = true;
      try {
        if (this.droppedFile.size === 0) {
          toastSubject.next(
            'The selected file "' +
              this.droppedFile.name +
              '" has been renamed or doesn\'t exist'
          );
          throw Error;
        }

        const casesIds: string[] = [];
        Array.from(this.selectedCases.keys()).forEach((caze) => {
            casesIds.push(caze.id);
        });

        const filename = normalizeFilename(this.droppedFile.name);
        formData.append('files', this.droppedFile, filename);
        filesInfo.push({
          fileName: filename,
          type: this.fileCategory,
          comment: this.fileComment,
          caseIds: casesIds,
          debtorId: this.loadedDebtorId,
        });
      } catch (error) {
        this.uploading = false;
        return;
      }

      formData.append(
        'filesInfo',
        new Blob([JSON.stringify(filesInfo)], {
          type: 'application/json',
        })
      );
      uploadFile(formData)
        .then((res) => {
          if (res.error) {
            throw new Error(res.error);
          }

          checkFileUploadResponse(res.data, this.droppedFile)
            .then(() => {
              resolve();
              this.droppedFile = undefined;
              this.uploading = false;
              toastSubject.next(PopupEvent.UPLOAD_FILE_SUCCESS);
              if (!this.loadedDebtorId) {
                return;
              }
              this.getFiles(this.loadedDebtorId);
            })
            .catch((err) => {
              this.uploading = false;
              toastSubject.next(err);
            });
        })
        .catch((err) => {
          this.uploading = false;
          toastSubject.next(err.message);
        });
    });
  };

  public toggleCase = (checked: boolean, caze: LetterCase) => {
    if (checked) {
      this.selectedCases.set(caze, true);
      return;
    }
    this.selectedCases.delete(caze);
  };

  public clearFilter = () => {
    this.selectedCases.clear();
  };

  public selectFile = (selectedFile: InfoFile) => {
    this.selectedFile = selectedFile;
  };

  public selectLetter = (selectedLetter: Letter) => {
    this.selectedLetter = selectedLetter;
  };

  public getLettersForCases = () => {
    this.deselectLetters();
    this.loadingLetters = true;
    const casesIds: string[] = [];
    Array.from(this.selectedCases.keys()).forEach((caze) => {
      casesIds.push(caze.oneStepId);
    });
    if (!casesIds.length) {
      return;
    }
    getSelectedLetters(casesIds)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.letters = res.data;
        this.loadingLetters = false;
      })
      .catch((err) => {
        toastSubject.next(err.message);

        this.loadingLetters = false;
      });
  };

  public getLettersAll = (debtorId: string) => {
    this.deselectLetters();
    this.loadingLetters = true;
    getLettersAll(debtorId, appState.showClosed)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.letters = res.data;
        this.loadingLetters = false;
      })
      .catch((err) => {
        toastSubject.next(err.message);

        this.loadingLetters = false;
      });
  };

  public getLettersCases = (debtorId: string) => {
    this.loadingCases = true;
    getLettersCases(debtorId, appState.showClosed)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        this.cases = this.sortCases(res.data);
        this.loadingCases = false;
      })
      .catch((err) => {
        toastSubject.next(err.message);
        this.loadingCases = false;
      });
  };

  sortCases = (data: LetterCase[]) => {
    return data.sort((a, b) => {
      if (!a.warrant && b.warrant) {
        return 1;
      }
      if (a.warrant && !b.warrant) {
        return -1;
      }
      if (!a.warrant && !b.warrant) {
        return b.clientReference.localeCompare(a.clientReference);
      }
      const warrantA = a.warrant!;
      const warrantB = b.warrant!;
      if (
        (warrantA.warrantDate ||
          warrantA.liabilityOrderDate ||
          warrantA.offenceDate) &&
        (warrantB.warrantDate ||
          warrantB.liabilityOrderDate ||
          warrantB.offenceDate)
      )
        return (
          (warrantB.warrantDate ||
            warrantB.liabilityOrderDate ||
            warrantB.offenceDate!) -
          (warrantA.warrantDate ||
            warrantA.liabilityOrderDate ||
            warrantA.offenceDate!)
        );
      if (
        (warrantA.warrantDate ||
          warrantA.liabilityOrderDate ||
          warrantA.offenceDate) &&
        !(
          warrantB.warrantDate ||
          warrantB.liabilityOrderDate ||
          warrantB.offenceDate
        )
      )
        return -1;
      if (
        !(
          warrantA.warrantDate ||
          warrantA.liabilityOrderDate ||
          warrantA.offenceDate
        ) &&
        (warrantB.warrantDate ||
          warrantB.liabilityOrderDate ||
          warrantB.offenceDate)
      )
        return 1;
      return b.clientReference.localeCompare(a.clientReference);
    });
  };

  public printLetter = () => {
    if (!this.selectedLetter) {
      return;
    }
    this.downloadingLetter = true;

    printLetter(this.selectedLetter.id)
      .then((res) => {
        if (res.error) {
          throw new Error(res.error);
        }
        if (!res.data || !res.data.content) {
          throw new Error("The server didn't return a valid letter");
        }
        if (res.data.isEmail) {
          openHtmlPage(res.data.content, 'Email preview')
              .then(() => {
                this.downloadingLetter = false;
              })
              .catch(() => {
                toastSubject.next('Something went wrong');
                this.downloadingLetter = false;
              });
        } else {
          openFile(
              blobToFile(
                  b64toBlob(res.data.content, 'application/pdf'),
                  res.data.name ||
                  'letter' +
                  moment().format(DATE_FORMAT_SHORTER).toString() + '.pdf'
              )
          )
              .then(() => {
                this.downloadingLetter = false;
              })
              .catch(() => {
                toastSubject.next('Something went wrong');
                this.downloadingLetter = false;
              });
        }
      })
      .catch((err) => {
        toastSubject.next(err.message);

        this.downloadingLetter = false;
      });
  };

  public getFiles = (debtorId: string) => {
    this.deselectFiles();
    this.loadingFiles = true;
    getFiles(debtorId, appState.showClosed)
      .then((res) => {
        this.loadingFiles = false;
        if (res.error) {
          throw new Error(res.error);
        }
        this.files = res.data;
      })
      .catch((err) => {
        this.loadingFiles = false;
        toastSubject.next(err.message);
      });
  };

  public getSelectedFiles = (debtorId: string) => {
    this.deselectFiles();
    this.loadingFiles = true;
    const casesIds: string[] = [];
    Array.from(this.selectedCases.keys()).forEach((caze) => {
      casesIds.push(caze.oneStepId);
    });
    if (!casesIds.length) {
      return;
    }
    getSelectedFiles(debtorId, casesIds)
      .then((res) => {
        this.loadingFiles = false;
        if (res.error) {
          throw new Error(res.error);
        }
        this.files = res.data;
      })
      .catch((err) => {
        this.loadingFiles = false;
        toastSubject.next(err.message);
      });
  };

  public resetFileUploader = () => {
    if (this.uploading) {
      return;
    }
    this.droppedFile = undefined;
    this.fileCategory = undefined;
    this.fileComment = '';
  };

  public deselectAll = () => {
    this.deselectFiles();
    this.deselectLetters();
  };

  public deselectFiles = () => {
    this.noPreview = false;
    this.selectedFile = null;
    this.fileToShowURL = null;
  };

  public deselectLetters = () => {
    this.selectedLetter = null;
  };

  public reset = () => {
    this.initialized = false;
    this.loadedDebtorId = null;
    if (typeof this.autorunner1 === 'function') {
      this.autorunner1();
      this.autorunner2();
    }

    this.deselectAll();

    this.files = [];
    this.letters = [];

    if (this.subscriptions) {
      this.subscriptions.unsubscribe();
    }
    this.fileCategory = undefined;
    this.fileComment = '';
  };
}

export const filesContext = createContext(new FilesState());
