import { Button } from 'components/common/button/Button';
import { observer } from 'mobx-react-lite';
import queryString from 'query-string';
import React, { useContext, useEffect } from 'react';
import { __RouterContext } from 'react-router';
import validate from 'validate.js';
import { REGEXPS } from '../../config';
import { passwordContext } from '../../state/passwordState';
import { profileContext } from '../../state/profileState';
import { Input } from '../common/input/Input';
import { validator } from '../common/input/validator';

export const ResetForm = observer(() => {
  const routerState = useContext(__RouterContext);

  const passwordState = useContext(passwordContext);
  const profileState = useContext(profileContext);

  useEffect(() => {
    if (profileState.passwordExpired) {
      return;
    }
    const queries = queryString.parse(routerState.location.search);
    if (queries.t && typeof queries.t === 'string') {
      passwordState.changeToken(queries.t as string);
    } else {
      routerState.history.push('/login');
    }
  }, [profileState.passwordExpired, passwordState, routerState]);

  const backToLogin = () => {
    if (profileState.authenticated) {
      profileState.logout();
    } else {
      routerState.history.push('/login');
    }
  };

  const submit = () => {
    validator
      .validate('ResetForm', true)
      .then(() => {
        if (profileState.passwordExpired && profileState.profileData) {
          passwordState
            .submitPassword(profileState.profileData.login)
            .then(() => {
              routerState.history.push('/login');
            });
        } else {
          passwordState.submitPasswordWithToken().then(() => {
            setTimeout(() => {
              profileState.logout();
            }, 1500);
          });
        }
      })
      .catch(() => 0);
  };

  return (
    <div className="login-form">
      {profileState.passwordExpired ? (
        <p className="expired" role="alert">
            {profileState.passNotChangedYet ?
                'Please set a new password before logging in the first time.' :
                'Your password has expired. Please set a new password below.'
            }
        </p>
      ) : null}
      <label htmlFor="newPassword-input">New password</label>
      <Input
        width='block'
        validateFunc={() =>
          validate.single(passwordState.newPassword, {
            presence: {
              allowEmpty: false,
            },
            length: {
              minimum: 14,
              tooShort: 'At least 14 characters are required',
              maximum: 64,
              tooLong: 'No more than 64 characters are allowed'
            },
            format: {
              pattern: REGEXPS.PASSWORD,
              message: 'Too simple or contains invalid characters.\nMust contain both letters and numbers.\nSupported special characters: $%@#£€*?&',
            },
          })
        }
        formName={'ResetForm'}
        type="password"
        placeholder="Enter your new password"
        name="newPassword"
        value={passwordState.newPassword}
        onChange={passwordState.changeNewPassword}
      />
      <label htmlFor="password-input">Confirm new password</label>
      <Input
        width='block'
        formName={'ResetForm'}
        validateFunc={() =>
          passwordState.confirmPassword === passwordState.newPassword
            ? undefined
            : ["Passwords don't match"]
        }
        type="password"
        placeholder="Repeat your new password"
        name="newPasswordConfirm"
        value={passwordState.confirmPassword}
        onChange={passwordState.changeConfirmPassword}
      />
      <p>
        Password must be at least 14 characters and no more than 64 characters long, must contain both
        letters and numbers and at least one character with upper case.
      </p>
      <div className="actions">
        <Button
          width="normal"
          onClick={backToLogin}
          className="btn btn-primary"
        >
          Login
        </Button>

        <Button
          width="normal"
          onClick={submit}
          className="btn btn-success"
          loading={passwordState.submitting}
        >
          Change password
        </Button>
      </div>
    </div>
  );
});
