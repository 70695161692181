import { ModalState } from "../../state/modalState";

export class Actions {
  constructor(modalState: ModalState) {
    this.modalState = modalState;
  }
  modalState: ModalState;

  getActions = () => {
    return [
      {
        scope: { type: "both", single: false, condition: "placeOnHold" },
        action: () => {
          this.modalState.toggleModal("placeOnHold", true);
        },
        name: "Place on hold"
      },
      {
        scope: { type: "both", single: false, condition: "takeOffHold" },
        action: () => {
          this.modalState.toggleModal("takeOffHold", true);
        },
        name: "Take off hold"
      },
      {
        scope: { type: "both", single: false, condition: "requestReturn" },
        action: () => {
          this.modalState.toggleModal("requestReturn", true);
        },
        name: "Request return"
      },
      {
        scope: { type: "case", single: true, condition: "liveCase" },
        action: () => {
          this.modalState.toggleModal("changeDebt", true);
        },
        name: "Change debt amount"
      },
      {
        scope: { type: "case", single: true, condition: "liveCase" },
        action: () => {
          this.modalState.toggleModal("addPayment", true);
        },
        name: "Add direct payment"
      },
      {
        scope: { type: "both", single: false, condition: "fileUpload" },
        action: () => {
          this.modalState.toggleModal("uploadFile", true);
        },
        name: "Upload file"
      },
      {
        scope: { type: "both", single: false, condition: "contactDetails" },
        action: () => {
          this.modalState.toggleModal("addContact", true);
        },
        name: "Add contact details"
      },
      {
        scope: { type: "both", single: false, condition: "note" },
        action: () => {
          this.modalState.toggleModal("addNote", true);
        },
        name: "Add note"
      },
      {
        scope: { type: "both", single: false, condition: "message" },
        action: () => {
          this.modalState.toggleModal("messageJBW", true);
        },
        name: "Message " + window.COMPANY_LABEL
      }
    ];
  };

  getDebtorActions = () => {
    return [
      {
        scope: { type: "both", single: false, condition: "contactDetails" },
        action: () => {
          this.modalState.toggleModal("addContact", true);
        },
        name: "Add contact details"
      },
      {
        scope: { type: "both", single: false, condition: "note" },
        action: () => {
          this.modalState.toggleModal("addNote", true);
        },
        name: "Add note"
      },
      {
        scope: { type: "both", single: false, condition: "message" },
        action: () => {
          this.modalState.toggleModal("messageJBW", true);
        },
        name: "Message " + window.COMPANY_LABEL
      }
    ];
  };
}
